import hoverintent from 'hoverintent';

export const initAdminMenu = () => {
	const adminMenu = document.getElementById("adminMenuLeft");

	if (adminMenu) {
		hoverintent(adminMenu,
			() => { adminMenu.classList.add("intent"); },
			() => { adminMenu.classList.remove("intent"); }
		).options({
			sensitivity: 30,
			timeout: 200,
			interval: 40
		});
	}

	[...document.querySelectorAll("#adminMenuLeft ul.withSubMenu")].map(function(el) {
		let id = el.querySelector('.label p').innerText;
		if (id) {
			id = 'menu' + id;
		}

		if (localStorage.getItem(id) === 'true') {
			el.classList.add('opened');
		}

		el.querySelector('li:first-child > .label')?.addEventListener('click', () => {
			el.classList.toggle('opened');

			if (id) {
				localStorage.setItem(id, el.classList.contains('opened').toString());
			}
		});
	});
}
