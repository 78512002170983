
export const initCookie = () => {
	let cookie = document.getElementById('cookieWarning');
	let cookieClose = document.getElementById('cookieClose');

	if (cookie && cookieClose) {
		cookieClose.addEventListener("click", (event) => {
			event.preventDefault();

			let date = new Date();
			document.cookie = "cookies=accepted; " + date.setTime(date.getTime() + (7 * (24 * 60 * 60 * 1000))) + "; path=/";

			cookie.classList.add("cookieHide");
		});
	}
}
